import React, { useState } from "react"
import { Helmet } from "react-helmet"

const Newsletter = () => {
  return (
    <div className="px-4 py-20 max-w-4xl mx-auto w-full ctct">
      <div
        className="ctct-inline-form"
        data-form-id="a1117cde-ac9a-4614-9bbd-1d56a44946ed"
      />
      <Helmet>
        <script> var _ctct_m = "ff2b9778a5a8de6f9f7af8eb05c7b3fc"; </script>
        <script
          id="signupScript"
          src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js"
          async
          defer
        ></script>
      </Helmet>
    </div>
  )
}

export default Newsletter
