import React from "react"
import LogoSymbol from "./logo-symbol"
import Newsletter from "./newsletter"

const FOOTER_TEXT =
  "Matt Brown Fine Art |  1 Main Street, On the Common, Lyme, NH 03768"
const PHONE_NUMBER = "603-795-4855"

const Footer = () => (
  <>
    <Newsletter />
    <footer className="text-center">
      <div className="bg-black text-white px-4 py-6 md:py-10 text-sm md:text-base">
        <LogoSymbol className="fill-current w-10 h-10 md:w-16 md:h-16 mx-auto text-white mb-4" />
        <p>{FOOTER_TEXT} </p>
        <p>
          <a href={`tel:${PHONE_NUMBER}`} className="no-underline">
            {PHONE_NUMBER}
          </a>
        </p>
      </div>
      <div className="bg-light py-2 text-xs">
        <p className="mb-0">
          Copyright © {new Date().getFullYear()} Matt Brown Fine Art, LLC — All
          Rights Reserved
        </p>
      </div>
    </footer>
  </>
)

export default Footer
