import React from "react"
import styles from "../styles/richtext.module.css"

const RichText = ({ html, className, type }) => (
  <div
    className={`${styles.rt} ${className} ${
      type === "Single Line" ? styles.single : ""
    }`}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
)

export default RichText
