import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import { handelize } from "../utils/utils"
import { filterValidNameFields } from "../utils/data"

const LINK_TEXT = "Artists"
const VIEW_ALL_LINK_TEXT = "View All"

const ArtistsNav = ({ sharedLinkClasses }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const [activeArtist, setActiveArtist] = useState(null)
  const handleClick = () => toggleExpansion(!isExpanded)

  useEffect(() => {
    if (typeof window !== "undefined" && !!window.document) {
      const html = document.querySelector("html")
      isExpanded
        ? (html.style.overflow = "hidden")
        : (html.style.overflow = "visible")
    }
  }, [isExpanded])

  const { airtableArtists } = useStaticQuery(graphql`
    {
      airtableArtists: allAirtable(
        filter: { table: { eq: "Artists" } }
        sort: { fields: data___Last_Name }
      ) {
        edges {
          node {
            data {
              Name
              Featured_Art {
                data {
                  Name
                  Images {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
              Hover_Artwork {
                data {
                  Name
                  Images {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const artistsWithHoverImage = filterValidNameFields(airtableArtists).filter(
    e =>
      (e.node.data.Featured_Art && e.node.data.Featured_Art[0].data.Images) ||
      (e.node.data.Hover_Artwork && e.node.data.Hover_Artwork[0].data.Images)
  )

  return (
    <li>
      <button
        className={classnames(sharedLinkClasses, "flex items-center ml-auto", {
          italic: isExpanded,
        })}
        onClick={handleClick}
      >
        {LINK_TEXT}
        <div
          className={classnames(
            "ml-3 border-b border-l w-2 h-2 border-current transform",
            {
              "-rotate-45": !isExpanded,
              "rotate-135": isExpanded,
            }
          )}
        />
      </button>
      <div
        className={`${
          isExpanded ? "md:absolute" : "hidden"
        } md:bg-light md:text-black right-0 w-full text-right md:px-4 md:z-20 md:pt-4 md:max-h-screen md:overflow-scroll md:mt-7 relative`}
      >
        <div className="flex justify-end max-w-4xl mx-auto">
          <div className="hidden md:block flex-1">
            {artistsWithHoverImage.map(({ node }, i) => {
              const { Featured_Art, Hover_Artwork } = node.data
              const image = Hover_Artwork
                ? Hover_Artwork[0].data.Images.localFiles[0].childImageSharp
                    .fluid
                : Featured_Art[0].data.Images.localFiles[0].childImageSharp
                    .fluid
              return (
                <div
                  key={i}
                  className={classnames("sticky top-0 w-full", {
                    hidden: activeArtist !== i,
                    block: activeArtist === i,
                  })}
                >
                  <Img className="w-full" fluid={image} />
                </div>
              )
            })}
          </div>
          <ul className="max-w-6xl md:pb-48 relative ml-auto pl-6">
            <li>
              <Link to={`/artists`} className="block pb-4 md:pb-6">
                {VIEW_ALL_LINK_TEXT}
              </Link>
            </li>
            {artistsWithHoverImage.map(({ node }, i) => {
              const { Name } = node.data
              return (
                <li key={i}>
                  <Link
                    to={`/${handelize(Name)}`}
                    className="no-underline md:text-2xl pb-3 block opacity-50 hover:opacity-100"
                    onMouseEnter={() => setActiveArtist(i)}
                    onMouseLeave={() => setActiveArtist(null)}
                  >
                    {Name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </li>
  )
}

export default ArtistsNav
