const moment = require(`moment`)

module.exports = {
  filterValidNameFields: data => {
    return data.edges.filter(e => !!e.node.data.Name)
  },

  dateInFuture: date => {
    return moment() <= moment(date)
  },

  dateInPast: date => {
    return moment() > moment(date)
  },

  formatHours: date => {
    return moment(date).format("hh:mma")
  },

  formatDate: date => {
    return moment(date).format("MMMM DD")
  },
}
