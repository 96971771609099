const handelize = string => {
  return string
    .toLowerCase()
    .replace(/[^\w\u00C0-\u024f]+/g, "-")
    .replace(/^-+|-+$/g, "")
}

const isEven = numb => {
  return numb === 0 || numb % 2 === 0
}

const smarten = a => {
  a = a.replace(/(^|[-\u2014\s(["])'/g, "$1\u2018") // opening singles
  a = a.replace(/'/g, "\u2019") // closing singles & apostrophes
  a = a.replace(/(^|[-\u2014/[(\u2018\s])"/g, "$1\u201c") // opening doubles
  a = a.replace(/"/g, "\u201d") // closing doubles
  a = a.replace(/--/g, "\u2014") // em-dashes
  return a
}

module.exports.handelize = handelize
module.exports.isEven = isEven
module.exports.smarten = smarten
