import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import RichText from "./richtext"

const AnnounceBar = () => {
  const data = useStaticQuery(graphql`
    {
      prismicSite {
        data {
          announcement_bar_text {
            html
          }
        }
      }
    }
  `)
  return (
    <div className="bg-black text-white text-center px-3 py-2 text-sm leading-tight">
      <RichText
        html={data.prismicSite.data.announcement_bar_text.html}
        type="Single Line"
      />
    </div>
  )
}

export default AnnounceBar
