import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import classnames from "classnames"

import ArtistsNav from "./artists-nav"

import LogoSymbol from "./logo-symbol"

const navLinks = [
  {
    text: "Artists",
    link: null,
  },
  {
    text: "Exhibits",
    link: "/exhibits/",
  },
  {
    text: "Events",
    link: "/events/",
  },
  {
    text: "Visit & Shop",
    link: "/visit-shop/",
  },
]

const Header = ({ isStuck }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const handleToggleExpand = () => toggleExpansion(!isExpanded)

  useEffect(() => {
    if (typeof window !== "undefined" && !!window.document) {
      const html = document.querySelector("html")
      isExpanded
        ? (html.style.overflow = "hidden")
        : (html.style.overflow = "visible")
    }
  }, [isExpanded])

  const sharedLinkClasses =
    "no-underline hover:italic pl-4 text-lg block pb-4 md:pb-0 text-right"

  return (
    <div className="">
      <nav
        className={classnames(
          "px-4 py-3 flex items-center justify-between relative w-full transition duration-200 ease-in-out",
          {
            "bg-white text-black fill-black": !isStuck,
            "bg-black text-white fill-white": isStuck,
          }
        )}
      >
        <Link
          to={`/`}
          className="flex items-center no-underline flex-shrink-0 mr-10"
        >
          <LogoSymbol className="fill-current w-8 h-8 md:w-12 md:h-12" />
          <h1 className="ml-4 text-xl md:text-2xl">Matt Brown Fine Art</h1>
        </Link>
        <button className="md:hidden" onClick={handleToggleExpand}>
          <span
            className={classnames(`c-close-btn w-6 h-6 block relative`, {
              "c-close-btn--expanded": isExpanded,
            })}
          />
        </button>
        <div
          className={classnames(
            "mt-mnav md:mt-0 z-20 absolute md:static top-0 left-0 md:block md:flex-grow w-full md:max-w-lg max-h-screen overflow-scroll md:overflow-visible transition duration-200 ease-in-out pb-40 md:pb-0",
            {
              block: isExpanded,
              hidden: !isExpanded,
            }
          )}
        >
          <div
            className={classnames("p-4 transition duration-200 ease-in-out", {
              "bg-white text-black fill-black": !isStuck,
              "bg-black text-white fill-white": isStuck,
            })}
          >
            <ul className="w-full md:flex md:justify-between">
              {navLinks.map(({ text, link }) => {
                if (text === "Artists") {
                  return (
                    <ArtistsNav
                      key={text}
                      sharedLinkClasses={sharedLinkClasses}
                    />
                  )
                } else if (!!link) {
                  return (
                    <li key={text}>
                      <Link to={link} className={sharedLinkClasses}>
                        {text}
                      </Link>
                    </li>
                  )
                }
                return <></>
              })}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
